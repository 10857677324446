//system
import React from 'react'
import { graphql } from 'gatsby'

//components
import LayoutMain from '../com/LayoutMain'
import Container from '../com/Container'
import Section from '../com/Section'
import Diagonal from '../com/Diagonal'
import Cta from '../com/Cta'
import H1 from '../com/H1'
import Footer from '../com/Footer'

const PrivacyPage = ({ data }) => {
  return (
    <LayoutMain>
      <header>
        <Diagonal>
          <Container>
            <H1>Your Data</H1>
            <Cta to="/">Back</Cta>
          </Container>
        </Diagonal>
      </header>
      <Section>
        <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        <Container>
          <Cta to="/">Back</Cta>
        </Container>
      </Section>
      <Footer />
    </LayoutMain>
  )
}

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/privacy/" }) {
      html
    }
  }
`

export default PrivacyPage
